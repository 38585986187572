[data-bs-theme=dark] .table-container-1 th:nth-of-type(1),
[data-bs-theme=dark] .table-container-1 td:nth-of-type(1) {

    background-color: #383846;
}

[data-bs-theme=dark] .table-container-1 th:nth-of-type(2),
[data-bs-theme=dark] .table-container-1 td:nth-of-type(2) {

    background-color: #383846;
}

.table-container-1 th:nth-of-type(1),
.table-container-1 td:nth-of-type(1) {
    background-color: #fbfbfb;
}

.table-container-1 th:nth-of-type(2),
.table-container-1 td:nth-of-type(2) {
    background-color: #fbfbfb;
}
